import React, { useState } from "react";
import PropTypes from "prop-types";
import Layout from "@app-components/layout/";
import { css } from "@emotion/core";
import Header from "@app-components/ui/header/top_bottom/";
import Agile from "@media/about/Agile.svg";
import Customer from "@media/about/Customer.svg";
import Cutting from "@media/about/Cutting.svg";
import Quality from "@media/about/Quality.svg";
import Map from "@page-components/about/Map";
import Stats from "@page-components/about/Stats";
import Chart from "@page-components/about/Chart";

const About = () => {
  const [country, setCountry] = useState("none");
  const [hoverSource, setHoverSource] = useState("map");
  return (
    <Layout page="about">
      <Header
        title={
          <h1 style={{ fontWeight: "300" }}>A fresh, modern approach to IFE</h1>
        }
        text="CineSend redefined theatrical content delivery through cloud technology and automation. Now we have our sights set on modernizing the IFE space."
      />
      <section className="py4 px2">
        <h2 className="center mb4">Headquartered in Toronto, CA</h2>
        <div
          className="max-width-4 mx-auto pb4"
          css={css`
            border-bottom: 1px solid #e1e3e4;
          `}
        >
          <div className="clearfix">
            <div className="sm-col sm-col-6 md-col-5 lg-col-4 mb3">
              <Chart country={country} hoverSource={hoverSource} />
              <Stats
                country={country}
                hoverSource={hoverSource}
                onCountryChange={country => {
                  setHoverSource("stats");
                  setCountry(country);
                }}
              />
            </div>
            <div className="sm-col sm-col-6 md-col-7 lg-col-8">
              <Map
                country={country}
                hoverSource={hoverSource}
                onCountryChange={country => {
                  setHoverSource("map");
                  setCountry(country);
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py4 px2">
        <h2 className="center mb4">Our Philosophy</h2>
        <div className="max-width-4 mx-auto pb4">
          <div className="clearfix mxn3">
            <div className="sm-col sm-col-12 md-col-3 mb3 center px3">
              <img
                src={Customer}
                css={css`
                  width: 50px;
                `}
                className="mb2"
              />
              <br />
              We're customer-driven; we add value by relieving pain
            </div>
            <div className="sm-col sm-col-12 md-col-3 mb3 center px3">
              <img
                src={Cutting}
                css={css`
                  width: 50px;
                `}
                className="mb2"
              />
              <br />
              We're at the cutting edge of evolving media technologies
            </div>
            <div className="sm-col sm-col-12 md-col-3 mb3 center px3">
              <img
                src={Agile}
                css={css`
                  width: 50px;
                `}
                className="mb2"
              />
              <br />
              We're agile and always improving our systems and processes
            </div>
            <div className="sm-col sm-col-12 md-col-3 mb3 center px3">
              <img
                src={Quality}
                css={css`
                  width: 50px;
                `}
                className="mb2"
              />
              <br />
              We believe it's possible to reduce costs in tandem with increasing
              quality
            </div>
          </div>
        </div>
      </section>
      {/* <Testimonial
        image={Profile}
        content={`
          We're excited to embark on a multi-year strategy to fundamentally change how airlines deliver premium media experiences to passengers both on the ground and in the air. The technology and automation solutions we've helped make commonplace upstream in the content delivery pipeline can be adapted to the airline industry to reduce costs and contribute to a better customer journey.
        `}
        name={"Colin Carter"}
        credit={"CEO, CineSend"}
      /> */}
    </Layout>
  );
};

About.propTypes = {};

export default About;
