import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import StatsNone from "@page-components/about/stats_none.svg";
import StatsAll from "@page-components/about/stats_all.svg";
import StatsLabsSales from "@page-components/about/stats_lab_sales.svg";
import StatsLab from "@page-components/about/stats_lab.svg";
import StatsSales from "@page-components/about/stats_sales.svg";
import StatsTech from "@page-components/about/stats_tech.svg";

const stats = {
  map: {
    none: StatsNone,
    canada: StatsAll,
    usa: StatsLabsSales,
    nordic: StatsLabsSales,
    india: StatsLab
  },
  stats: {
    none: StatsNone,
    lab: StatsLab,
    sales: StatsSales,
    tech: StatsTech
  }
};

const Stats = ({ onCountryChange, country, hoverSource }) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        onMouseEnter={() => onCountryChange("lab")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("lab")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 0%;
          width: 100%;
          height: 30%;
        `}
      ></div>
      <div
        onMouseEnter={() => onCountryChange("sales")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("sales")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 0%;
          top: 30%;
          width: 100%;
          height: 35%;
        `}
      ></div>
      <div
        onMouseEnter={() => onCountryChange("tech")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("tech")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 0%;
          top: 65%;
          width: 100%;
          height: 35%;
        `}
      ></div>
      <img
        src={stats[hoverSource][country]}
        css={css`
          width: 100%;
        `}
      />
    </div>
  );
};

export default Stats;
