import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import Chart from "@page-components/about/chart_none.svg";
import Chart20 from "@page-components/about/chart_20.svg";
import Chart20Top from "@page-components/about/chart_20_top.svg";
import Chart2020 from "@page-components/about/chart_20_20.svg";
import Chart202060 from "@page-components/about/chart_20_20_60.svg";
import Chart60 from "@page-components/about/chart_60.svg";

const charts = {
  map: {
    none: Chart,
    canada: Chart202060,
    usa: Chart2020,
    nordic: Chart2020,
    india: Chart20
  },
  stats: {
    none: Chart,
    lab: Chart20,
    sales: Chart20Top,
    tech: Chart60
  }
};

const Stats = ({ country, hoverSource }) => {
  return (
    <div
      css={css`
        position: relative;
        width: 60%;
        margin-bottom: 30px;
      `}
    >
      <img
        src={charts[hoverSource][country]}
        css={css`
          width: 100%;
        `}
      />
    </div>
  );
};

export default Stats;
