import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import MapImage from "@page-components/about/map.svg";
import Canada from "@page-components/about/map_canada.svg";
import Nordic from "@page-components/about/map_nordic.svg";
import Usa from "@page-components/about/map_usa.svg";
import India from "@page-components/about/map_india.svg";
import Labels from "@page-components/about/map_labels.svg";
import All from "@page-components/about/map_all.svg";
import SalesMap from "@page-components/about/map_canada_usa_nordic.svg";

const maps = {
  map: {
    none: MapImage,
    canada: Canada,
    nordic: Nordic,
    usa: Usa,
    india: India
  },
  stats: {
    none: MapImage,
    lab: All,
    sales: SalesMap,
    tech: Canada
  }
};

const Map = ({ onCountryChange, country, hoverSource }) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {/* Canada */}
      <div
        onMouseEnter={() => onCountryChange("canada")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("canada")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 7.5%;
          width: 25%;
          height: 34%;
          opacity: 0.5;
        `}
      ></div>

      {/* USA: Main */}
      <div
        onMouseEnter={() => onCountryChange("usa")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("usa")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 12%;
          top: 34%;
          width: 16%;
          height: 16%;
          opacity: 0.5;
        `}
      ></div>

      {/* USA: Alaska */}
      <div
        onMouseEnter={() => onCountryChange("usa")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("usa")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 0;
          top: 15%;
          width: 7.5%;
          height: 16%;
          opacity: 0.5;
        `}
      ></div>

      {/* Nordic */}
      <div
        onMouseEnter={() => onCountryChange("nordic")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("nordic")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 48%;
          top: 3%;
          width: 7.5%;
          height: 28%;
          opacity: 0.5;
        `}
      ></div>

      {/* India */}
      <div
        onMouseEnter={() => onCountryChange("india")}
        onMouseLeave={() => onCountryChange("none")}
        onClick={() => onCountryChange("india")}
        css={css`
          z-index: 2;
          position: absolute;
          left: 65%;
          top: 42%;
          width: 9%;
          height: 20%;
          opacity: 0.5;
        `}
      ></div>

      <div
        css={css`
          position: absolute;
          top: 0%;
          left: 0%;
          right: 0%;
          bottom: 0;
        `}
      >
        <img
          src={Labels}
          css={css`
            width: 100%;
          `}
        />
      </div>

      <img
        src={maps[hoverSource][country]}
        css={css`
          width: 100%;
        `}
      />
    </div>
  );
};

export default Map;
